/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setup': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.525 17.76a.858.858 0 00.22-.578V6.357a2 2 0 000-3.714V.818a.857.857 0 00-.22-.578.72.72 0 00-.53-.24.72.72 0 00-.53.24.857.857 0 00-.22.578v1.83a2 2 0 000 3.704v10.83c0 .217.078.425.22.578a.72.72 0 00.53.24.72.72 0 00.53-.24zm5.955 0a.858.858 0 00.22-.578v-2.308a2 2 0 000-3.748V.818A.857.857 0 009.48.24.72.72 0 008.95 0a.72.72 0 00-.53.24.857.857 0 00-.22.578v10.348a2 2 0 000 3.668v2.348c0 .217.079.425.22.578a.72.72 0 00.53.24.72.72 0 00.53-.24zm6.22-.578a.857.857 0 01-.22.578.72.72 0 01-.53.24.72.72 0 01-.53-.24.857.857 0 01-.22-.578V9.834a2 2 0 010-3.668V.818c0-.217.079-.425.22-.578a.72.72 0 01.53-.24.72.72 0 01.53.24c.141.153.22.361.22.578v5.308a2 2 0 010 3.748v7.308z" _fill="#898B9B"/>'
  }
})
